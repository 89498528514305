import React from 'react';

const Food = () => {
  const header = 'food';

  return (
    <div className='food'>
      {header}
    </div>
  )
}

export default Food;