import React from 'react';

const Music = () => {
  const header = 'music';

  return (
    <div className='music'>
      {header}
    </div>
  );
}

export default Music;