import React from 'react';

const Thoughts = () => {
  const header = 'bleep blopasdasd';

  return (
    <div className='thoughts'>
      {header}
    </div>
  )
}

export default Thoughts;