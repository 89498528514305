import React from 'react';

const Things = () => {
  const header = 'bleep blop';

  return (
    <div className='things'>
      {header}
    </div>
  )
}

export default Things;