import React from 'react';

const Genshin = () => {
  const header = 'asdasdasd';

  return (
    <div className='games'>
      {header}
    </div>
  )
}

export default Genshin;