import React from 'react';

const News = () => {
  const header = 'no hay nada';

  return (
    <div className='news'>
      {header}
    </div>
  )
}

export default News;