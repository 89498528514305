import React from 'react';

const Rom = () => {
  const header = 'bleep blop rom';

  return (
    <div className='rom'>
      {header}
    </div>
  )
}

export default Rom;